import React, { useEffect } from "react";

import { IMainSiteHeroFields } from "src/types/generated/contentful";
import BackgroundImageWithForm from "./layouts/ReferralProgram/BackgroundImageWithForm";

import MainSiteHero from "./layouts/MainSiteHero/MainSiteHero";

const getHero = (layoutType: any, data: any) => {
  switch (layoutType) {
    case "Background Image with Form":
      return <BackgroundImageWithForm {...data} />;

    default:
      return <MainSiteHero {...data} />;
  }
};

export default function Hero(data: any): React.ReactElement {
  //console.log("Hero:data", JSON.stringify(data));
  //console.log('Hero:data','');
  const { layoutType } =
    data;

  return <>{getHero(layoutType, data)}</>;
}
