import { Flex } from "@chakra-ui/react";
import { AssetFields, Entry } from "contentful";
import Image from "next/image";
import React from "react";
import Layout from "src/components/Layout";
import { INascarLogoFields } from "src/types/generated/contentful";
import {
  Col,
  DesktopHeader,
  Header,
  Heart,
  LogoCol,
  LogoGrid,
  MobileHeader,
  NascarLogosGrid,
  Wrapper,
} from "./styles";

export interface NascarLogosProps {
  nascarLogos: any;
  title?: string;
  subTitle?: string;
  themeName?: string;
  style?: any;
}

const NascarLogos: React.FC<NascarLogosProps> = (data) => {
  const { nascarLogos, title, subTitle, themeName, style } = data;
  const nascarLogosGrid = (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        {themeName && themeName?.indexOf("collapsed") >= 0 ? (
          <Header>{title}</Header>
        ) : null}
        <LogoGrid>
          {nascarLogos
            ? nascarLogos.map(
                (
                  logoDoc: Entry<{
                    fields: INascarLogoFields;
                    contentTypeId: "";
                  }>,
                  i: number
                ) => {
                  const logoDocF = logoDoc?.fields as INascarLogoFields;
                  const logoF = logoDocF?.logo?.fields as AssetFields;
                  return (
                    <LogoCol
                      collapsed={
                        themeName && themeName?.indexOf("collapsed") >= 0
                          ? true
                          : false
                      }
                      key={i}
                    >
                      <Image
                        alt={logoDocF?.title}
                        aria-label={logoDocF?.title}
                        src={`${logoF?.file?.url}`}
                        width={250}
                        height={250}
                        loading="lazy"
                        title={logoDocF?.title}
                      />
                    </LogoCol>
                  );
                }
              )
            : null}
        </LogoGrid>
      </Flex>
    </>
  );

  const mainGrid = (
    <NascarLogosGrid>
      <Col width={"35%"}>
        <Heart>
          <svg
            preserveAspectRatio="xMinYMin slice"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 31"
          >
            <path
              d="m21.31 17.02-5.94 5.96-5.9-5.99c-.89-.94-1.65-2-1.65-3.37 0-2.36 1.92-4.28 4.28-4.28 1.34 0 2.53.62 3.32 1.58.78-.96 1.98-1.58 3.32-1.58 2.36 0 4.28 1.92 4.28 4.28-.01 1.38-.76 2.53-1.71 3.4M15.41 0C6.9 0 0 6.9 0 15.41s6.9 15.41 15.41 15.41 15.41-6.9 15.41-15.41S23.92 0 15.41 0"
              fill="#FFF"
            />
          </svg>
        </Heart>

        <DesktopHeader>{title}</DesktopHeader>
        <MobileHeader>{subTitle}</MobileHeader>
      </Col>
      <Col width={"65%"}>{nascarLogosGrid}</Col>
    </NascarLogosGrid>
  );

  return (
    <>
      <Wrapper data-testid="Nascar" themeName={themeName} style={style}>
        <Layout>
          {themeName?.indexOf("collapsed") ? nascarLogosGrid : mainGrid}
        </Layout>
      </Wrapper>
    </>
  );
};

export default React.memo(NascarLogos);
