import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import StarRatings from "../StarRatings/StarRatings";

import ShopperApprovedLogo from "./ShopperApproved-Logo";

const ShopperApprovedBanner = () => {
  return (
    <Flex
      backgroundColor={"#E1F0FF"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={2}
    >
      <Flex
        gap={2}
        flexDirection={["column", "column", "row"]}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ShopperApprovedLogo />
        <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
          <Box fontWeight={"bold"}>4.9</Box>
          <StarRatings color={"#EB7100"} />
          <Box>Over 400+ Reviews</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(ShopperApprovedBanner);
