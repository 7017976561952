import { Link } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import NextLink from "next/link";
import Layout from "src/components/Layout";
import {
  IMainSiteHeroFields,
  IMainSiteHeroMenuItems,
  IMainSiteHeroMenuItemsFields,
  IPageFields,
} from "src/types/generated/contentful";
import header from "./Medicare-Bob_hero_header.jpeg";
import {
  ButtonWrapper,
  HeaderImage,
  HeaderText,
  HeroWrapper,
  MenuItemWrapper,
  Overlay,
  SubTitleText,
  TextWrapper,
  TitleText,
} from "./styles";
import ReactDOM from "react-dom";

const MainSiteHero = (data: IMainSiteHeroFields) => {
  // console.log("Hero:data:start", JSON.stringify(data), "Hero:data:end");
  const { titleArea, mainSiteHeroMenuItems } = data;

  return (
    <div style={{ background: "#D3EBF7" }}>
      <Layout style={{ padding: 0 }}>
        <HeroWrapper id="MainHero">
          <HeaderImage>
            <Image aria-hidden src={header} alt={""} fill priority/>
          </HeaderImage>
          <Overlay>
            <TextWrapper>
              <HeaderText data-testid="MainHeroTitle">
                {documentToReactComponents(titleArea)}
              </HeaderText>
            </TextWrapper>

            <ButtonWrapper>
              {mainSiteHeroMenuItems &&
                mainSiteHeroMenuItems.length > 0 &&
                mainSiteHeroMenuItems.map(
                  (item: IMainSiteHeroMenuItems, i: number) => {
                    const mainSiteHeroMenuItemsf =
                      item?.fields as IMainSiteHeroMenuItemsFields;
                    const { title, subTitle, buttonTitle } =
                      mainSiteHeroMenuItemsf;
                    //console.log("mainSiteHeroMenuItem", JSON.stringify(item));
                    const iPagef = mainSiteHeroMenuItemsf.link
                      .fields as IPageFields;

                    return (
                      <MenuItemWrapper key={i}>
                        <TitleText>{title}</TitleText>

                        <SubTitleText>{subTitle}</SubTitleText>
                        {iPagef?.slug ? (
                          <NextLink href={iPagef?.slug} passHref legacyBehavior>
                            <Link
                              variant={
                                i === 0
                                  ? "brand-solid-accent"
                                  : "brand-outline-accent"
                              }
                              aria-label={buttonTitle}
                              //linkToEntry={item.fields.link}
                            >
                              {buttonTitle}
                            </Link>
                          </NextLink>
                        ) : null}
                      </MenuItemWrapper>
                    );
                  }
                )}
            </ButtonWrapper>
          </Overlay>
        </HeroWrapper>
      </Layout>
    </div>
  );
};

export default React.memo(MainSiteHero);
