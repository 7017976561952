import Layout from "src/components/Layout";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const HeroWrapper = styled.section`
  position: relative;
  min-height: 424px;
  max-height: 554px;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${down("md")} {
    min-height: unset;
    max-height: unset;
    padding: 0;
  }
`;

export const Overlay = styled(Layout)`
  z-index: 1;
  position: relative;
  top: 0;
  display: block;

  padding-right: 35%;
  padding-left: 5%;

  ${down("sm") && up("xxl")} {
    padding-left: 0%;
  }

  ${down("md")} {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderImage = styled.div`
  span {
    height: 554px;
    z-index: 0;

    ${down("md")} {
      width: 1500px;
      max-width: 1500px;
      min-width: 1500px;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: top left;

    ${down("md")} {
      position: relative;
      height: 300px;
    }
  }
`;

export const HeaderText = styled.span`
  p {
    margin: 0;
    font-size: 3rem;
    font-weight: 900;
    line-height: 1em;
    letter-spacing: -1px;
    color: ${({ theme }) => theme.palette.common.darkBlue};
    font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};

    ${down("md")} {
      font-size: 3rem;
      text-align: center;
    }
  }
`;

export const TitleText = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1em;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.palette.common.darkBlue};
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};

  ${down("md")} {
    font-size: 6vw;
  }
`;

export const SubTitleText = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};

  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0;
  margin: 0.25em 0 1.5em 0;
  color: ${({ theme }) => theme.palette.common.darkBlue};

  ${down("md")} {
    font-size: 4vw;
  }
`;

export const MenuItemWrapper = styled.div`
  flex: 1;
  ${down("md")} {
    margin-bottom: 3.5em;
  }
`;

export const ButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 1em;
  padding: 2em;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 2em;
  margin-bottom: 2em;
  white-space: nowrap;
  width: 85%;

  ${down("md")} {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-top: 0;

    font-size: 3.5vw;
  }
`;

export const TextWrapper = styled.div`

  ${down("md")} {
    padding: 0;
  }
`;
