import { between, down, up } from "styled-breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div<{ themeName?: string }>`
  padding: 2% 5%;
  color: white;
  ${({ theme, themeName }) =>
    `background-color: ${
      themeName === "Medium"
        ? theme.palette.primary.main
        : theme.palette.common.darkBlue
    };`}

  overflow: hidden;

  ${down("sm")} {
    padding: 2%;
  }
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
  font-size: 1.4em;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1em;
  letter-spacing: 1.1px;
  padding-bottom: 0.4em;
  margin: 1em 0 0 0;

  ${down("md")} {
    font-size: 2.1em;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: 0px;
    text-align: center;
  }

  ${down("sm")} {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: 0.5px;
  }
`;

export const DesktopHeader = styled(Header)`
  ${down("md")} {
    display: none;
  }
`;
export const MobileHeader = styled(Header)`
  ${up("md")} {
    display: none;
  }
`;

interface GridProps {
  $noPaddingTop?: boolean;
  width?: string;
}
export const NascarLogosGrid = styled.div<GridProps>`
  padding: 2em 0;
  margin: 0 -10px -12px;

  ${up("md")} {
    display: flex;
    flex-wrap: wrap;
    padding: 2% 0;
  }

  ${({ $noPaddingTop }) => $noPaddingTop && `padding-top: 0;`}
`;

export const LogoGrid = styled.div<GridProps>`
  padding: 2em 0;
  margin: 0 -10px -12px;
  display: flex;
  flex-wrap: wrap;

  ${up("md")} {
    padding: 2% 0;
  }

  ${({ $noPaddingTop }) => $noPaddingTop && `padding-top: 0;`}
`;

export const Col = styled.div<GridProps>`
  padding: 10px;
  flex: 1;
  position: relative;
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.width || "100%"};
  min-width: ${(props) => props.width || "100%"};

  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${down("md")} {
    text-align: center;
    min-width: 50%;
    max-width: 100%;
    width: 100%;

    &:first-child {
      min-width: 100%;
    }
  }
`;
export const Heart = styled.div`
  svg {
    display: block;
    width: 2em;
    height: 2em;
    margin: 1em 0;
  }
  ${down("md")} {
    display: none;
  }
`;

export const LogoCol = styled.div<{ collapsed: boolean }>`
  padding: 10px;
  flex: 1;
  ${(props) =>
    props?.collapsed
      ? null
      : `width: 30%;
  max-width: 30%;
  min-width: 30%;
  `}
  ${(props) => (props?.collapsed ? `opacity: 0.8;` : `opacity: 1;`)}

  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${down("md")} {
    text-align: center;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
  }

  ${down("sm")} {
    &:nth-child(odd):last-child {
      align-items: center;
      flex: 1;
      display: flex;
      min-width: 100%;
      width: 100%;

      img {
        width: 50%;
      }
    }
  }
`;

export const TopGrid = styled(NascarLogosGrid)`
  ${between("md", "lg")} {
    ${Col} {
      flex: none;
      width: 33.3%;
    }
  }

  ${down("sm")} {
    display: flex;
    flex-wrap: wrap;

    ${Col} {
      flex: none;
      width: 50%;
    }
  }
`;

interface MenuListProps {
  $first?: boolean;
}
export const MenuList = styled.ul<MenuListProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ $first }) => !$first && `margin-top: 30px;`}
`;

export const MenuListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  a {
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-decoration: none;

    ${down("sm")} {
      line-height: 1.4em;
    }
  }
`;

export const MenuExtraCol = styled(Col)`
  display: none;
  ${between("md", "lg")} {
    display: block;
  }
`;

export const Address = styled.div`
  color: #ffffff;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.5px;
`;

export const Phone = styled(Address)`
  line-height: 1.2em;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid #005e667a;
  margin: 0;

  ${up("xl")} {
    margin-left: -5%;
    margin-right: -5%;
  }
`;

export const SecondaryText = styled.div`
  color: #f5fcff94;
  font-family: "Roboto", Sans-serif;
  font-size: 0.85em;
  font-weight: 400;
`;

export const PrivacyPolicyCol = styled(Col)`
  flex: none;

  a {
    font-size: 0.8em;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: 0.5px;
    color: #f5fcff94;
    text-decoration: none;
  }

  ${up("md")} {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const SmallImage = styled.img`
  max-width: 130px;
`;

export const SocialLinks = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  flex: none;
  display: inline-flex;
  width: 1em;
  height: 1em;
  cursor: pointer;
  font-size: 19px;
  margin-right: 30px;

  > img {
    width: 100%;
    height: 100%;
  }

  ${down("sm")} {
    font-size: 22px;
    margin-right: 8px;
  }
`;
