import { Box, Flex, useMediaQuery, useTheme } from "@chakra-ui/react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "@emotion/styled";
import ShopperApprovedBanner from "@src/components/atoms/ShopperApprovedBanner/ShopperApprovedBanner";
import NascarLogos from "@src/components/molecules/NascarLogos/NascarLogos";
import { INascarLogosFields } from "@src/types/generated/contentful";
import Image from "next/image";
import React, { useEffect } from "react";
import Layout from "src/components/Layout";
import FlexDisplayItem from "src/components/atoms/FlexDisplayItem";
import { down } from "styled-breakpoints";
import { useWindowSize } from "usehooks-ts";

const HeaderImage = styled.div`
  position: relative;
  max-height: 500px;
  height: 500px;
  width: 100%;

  img {
    object-position: unset !important;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: right center;
  }
`;

const TitleWrapper = styled.div<{ theme?: any; showFormRight: boolean }>`
  width: 50%;
  ${down("lg")} {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    text-align: ${({ showFormRight }) => (showFormRight ? "right" : "left")};
    font-size: ${({ theme }) => theme.fontSizes["5xl"]};
    margin-top: 0.5rem;

    ${down("lg")} {
      font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    }
  }
  p {
    text-align: ${({ showFormRight }) => (showFormRight ? "right" : "left")};
  }
`;

const HeroWrapper = styled.div`
  position: relative;

  table {
    width: 100%;
    td {
      padding: 0.4rem;
    }
  }
`;

const AlignmentWrapper = styled.div`
  position: relative;

  ${down("lg")} {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 2em;
    top: -20px;
    height: unset !important;
    > div {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.5rem;
  }

  ${down("md")} {
    padding: 0;
  }
`;

const LayoutWrapper: React.FC<any> = (data) => {
  const {
    header,
    titleArea,
    formComponent,
    backgroundImage,
    showFormOnTheRight,
    content,
  } = data;

  // console.log("backgroundImage", backgroundImage);

  const url = `https:${backgroundImage?.fields?.file?.url}`;

  const formWrapperRef = React.useRef(null);
  const formAreaRef = React.useRef(null);

  const { width, height } = useWindowSize();

  let nascarLogos;
  if (content && content.length > 0) {
    nascarLogos = content[0].fields as INascarLogosFields;
  }

  //let [backgroundPosition, setBackgroundPosition] = useState({});

  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    const bp = !isMobile
      ? {}
      : {
          // objectPosition: "bottom -170px right -180px",
          // backgroundColor: "var(--chakra-colors-brandAccent-500)",
          // objectFit: "cover",
        };

    //setBackgroundPosition(bp);
  }, [isMobile]);

  return (
    <div
      style={{
        background: "#052343",
      }}
    >
      <HeroWrapper id="MainHero">
        <FlexDisplayItem
          themeName="dark"
          fh={!showFormOnTheRight ? "right" : "left"}
          style={{
            backgroundColor: theme.colors.brandPrimary["500"],
            padding: theme.sizes["3"],
          }}
          data-testid="MainHeroTitle"
        >
          <Layout>
            <TitleWrapper showFormRight={!showFormOnTheRight}>
              {documentToReactComponents(titleArea)}
            </TitleWrapper>
          </Layout>
        </FlexDisplayItem>
        <Box
          ref={formAreaRef}
          position="relative"
          id="FormArea"
          style={{ backgroundColor: "#192f43" }}
        >
          <Layout style={{ padding: 0 }}>
            <Flex
              justifyContent="center"
              maxHeight={["unset", "unset", "unset", "500px"]}
              display={["block", "block", "block", "flex"]}
              flexDirection={["column", "column", "column", "row"]}
            >
              <HeaderImage data-id="header-image">
                {/* <AspectRatio ratio={4 / 3}> */}
                <Image
                  aria-hidden
                  src={url}
                  alt={""}
                  fill
                  priority

                  //style={backgroundPosition}
                />
                {/* </AspectRatio> */}
              </HeaderImage>
              <Flex wrap="wrap" data-id="form-container">
                <Flex
                  data-id="form-wrapper"
                  position="relative"
                  zIndex={1}
                  top={["unset", "unset", "unset", "-120px"]}
                >
                  <AlignmentWrapper
                    ref={formWrapperRef}
                    data-id="alignment-wrapper"
                  >
                    {formComponent}
                  </AlignmentWrapper>
                </Flex>
                ‚
              </Flex>
            </Flex>
          </Layout>
        </Box>
        <Box backgroundColor="#E1F0FF">
          <Layout>
            <Flex
              maxWidth={["unset", 750]}
              alignItems={"center"}
              justifyContent={[
                "center",
                "center",
                "center",
                "flex-start",
                "center",
              ]}
            >
              <Box maxWidth={600}>
                <ShopperApprovedBanner />
              </Box>
            </Flex>
          </Layout>
        </Box>
        {/*<BlockRenderer block={data?} data={data} />*/}
        <Box backgroundColor="brandPrimary.500">
          <Layout>
            <Box maxWidth={["unset", "unset", "unset", 500, 750]}>
              <NascarLogos
                themeName={"dark collapsed"}
                title={nascarLogos?.title}
                nascarLogos={nascarLogos?.nascarLogos}
                style={{
                  padding: 0,
                }}
              />
            </Box>
          </Layout>
        </Box>
      </HeroWrapper>
    </div>
  );
};

export default React.memo(LayoutWrapper);
