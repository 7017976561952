import React from 'react';
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { IMainSiteHeroFields } from "src/types/generated/contentful";
import LayoutWrapper from "../../LayoutWrapper";

export default function BackgroundImageWithForm(data: IMainSiteHeroFields) {
  const { form } = data;
  return (
    <LayoutWrapper
      {...data}
      formComponent={
        <BlockRenderer block={form} layoutId={"LandingImageLeft"} />
      }
    />
  );
}
