import { StarIcon } from "@chakra-ui/icons";
import React from "react";

import { Flex } from "@chakra-ui/react";

interface IStarRatingsProps {
  size?: "md" | "lg" | "xl" | "sm" | "xs";
  color?: string;
}

const StarRatings = ({ size, color }: IStarRatingsProps) => {
  const sz = size || "md";
  const clr = color || "yellow.400";
  return (
    <Flex direction="row" justifyContent="center" alignItems="center">
      <StarIcon color={clr} fontSize={sz} mr="1" />
      <StarIcon color={clr} fontSize={sz} mr="1" />
      <StarIcon color={clr} fontSize={sz} mr="1" />
      <StarIcon color={clr} fontSize={sz} mr="1" />
      <StarIcon color={clr} fontSize={sz} mr="1" />
    </Flex>
  );
};
export default React.memo(StarRatings);
